import Head from 'next/head'
import { useRouter } from 'next/router'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb'
import { BreadcrumbItem } from '@/components/Breadcrumb/breadcrumb.model'
import styles from './cookieSettingsPage.module.scss'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import { useCookies } from 'react-cookie'
import React, { useRef, useEffect } from 'react'
import { showSuccessToast } from '@/utils/toast/toast'
import {
  ResponseData,
  CookiesData,
  CheckBoxData,
} from '@/modules/cookie-settings/cookieSettings.model'
import Custom404 from '@/modules/custom404/Custom404'
import USBCheckbox from '@usb-shield/react-forms-checkbox'
import { isCookieExists, setExpirationDays, cleanUp} from '@/utils/cookies/cookies'
import * as Tealium from '@/modules/tealium/Tealium'

const leftBlock: any = {
  span: null,
  spans: {
    xlarge: 6,
    large: 6,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

export const SETTINGS_SAVED_MSG = 'Your cookies settings have been saved.'

const CookieSettings = (responseData: ResponseData) => {
  const cookieData: CookiesData = responseData?.cookiesData?.data
  const checkboxRef = useRef()
  const breadcrumbItems: BreadcrumbItem[] = [
    { id: 2, text: 'Cookie Settings' || '', href: '' },
  ]
  const [cookies, setCookie, removeCookie] = useCookies()
  const router = useRouter()

  useEffect(() => {
    if (!cookies.acceptCookies) {
      setCookie(
        'acceptCookies',
        ['Strictly Necessary Cookies'],
        { path: '/', expires: setExpirationDays(90) }
      )
    }

    if(!router.isReady) return;
  }, [router.isReady])

  function getCurrentCookies() {
    return (cookies['acceptCookies'] && cookies['acceptCookies'].length)
      ? cookies['acceptCookies']
      : []
  }

  function getCurrentCookiesAsAList(event: any, currentCookies: any) {
    return currentCookies && currentCookies.length
      ? [...currentCookies, event.inputValue.name.trim()]
      : [event.inputValue.name.trim()]
  }

  function getCurrentCookiesAsAFilteredList(event: any, currentCookies: any) {
    return currentCookies && currentCookies.length
      ? currentCookies.filter(
          (eachItem: string) => eachItem.trim() !== event.inputValue.name.trim()
        )
      : []
  }

  const handleStatusUpdate = (event: any) => {
    const currentCookies = getCurrentCookies()
      
    if (event.inputValue.checked && event.event == 'onChange') {
      const list = getCurrentCookiesAsAList(event, currentCookies)

      setCookie(
        'acceptCookies', 
        list, 
        { path: '/', expires: setExpirationDays(90) }
      )
      
      showSuccessToast(SETTINGS_SAVED_MSG)
      Tealium.cookieSettingsChanged(list, router)
    } else if (!event.inputValue.checked && event.event == 'onChange') {
      const list = getCurrentCookiesAsAFilteredList(event, currentCookies)
        
      if (list.length > 0) {
        if (!isCookieExists(list, "Performance/Analytics Cookies")) {
          cleanUp(cookies)
        }

        setCookie(
          'acceptCookies', 
          list, 
          { path: '/', expires: setExpirationDays(90) }
        )
      } else if (list.length === 0) {
        removeCookie(
          'acceptCookies', 
          { path: '/', expires: setExpirationDays(90) }
        )
      }

      showSuccessToast(SETTINGS_SAVED_MSG)
      Tealium.cookieSettingsChanged(list, router)
    }
  }

  const renderCookieListData = () => {
    return cookieData?.cookieCheckBoxes.map((eachCookie: CheckBoxData, i) => {
      let dynamicValue = false

      cookies.acceptCookies.forEach((cookie: string) => {
        if (eachCookie.label.toLowerCase().trim() === cookie.toLowerCase().trim()) {
          dynamicValue = true
        }
      })
      
      return (
          <div className={styles.eachCookieList} key={`cookie-${i}`}>
            <USBCheckbox
              ref={checkboxRef}
              callbackFrequency="every"
              inputName={eachCookie.label}
              labelText={eachCookie.label}
              isDisabled={eachCookie.defaultValues}
              dynamicValue={dynamicValue}
              addClasses={styles.defaultCheckStyle}
              statusUpdateCallback={(e: any) => handleStatusUpdate(e)}
              dataTestId="cookieCheckbox"
            >
              <p className={styles.cookieDescription}>
                {eachCookie.shortDescription}
              </p>
            </USBCheckbox>
          </div>
      )
    })
  }

  if (
    !(
      cookieData &&
      Object.keys(cookieData) &&
      Object.keys(cookieData).length
    )
  ) {
    return <Custom404 />
  }

  return (
    <>
      <Head>
        <title>Cookie settings | Developer Portal</title>
      </Head>
      <Breadcrumb items={breadcrumbItems} bgEven={false}></Breadcrumb>
      <USBGrid
        gridGap="zero"
        addClasses={styles.gridWrapper}
        alignItems="stretch"
        columnCount="16"
        justifyContent="stretch"
        display="grid"
        padding="normal"
      >
        <USBColumn layoutOpts={leftBlock} addClasses={styles.cookiesBlock}>
          <h1
            className={styles.cookiesTitle}
            id="cookiesTitle"
            data-testid="cookiesTitle"
          >
            {cookieData?.cookieSettingsTitle}
          </h1>
          <div className={styles.cookieWrapper}>
            <div>
              {cookies.acceptCookies &&
                cookies.acceptCookies.length > 0 &&
                renderCookieListData()}
            </div>
          </div>
        </USBColumn>
      </USBGrid>
    </>
  )
}

export default CookieSettings
