import { useSession } from 'next-auth/react'
import { PageHeader } from '@/components/PageHeader/PageHeader'
export interface GlobalData {
  pageHeaderData?: any
  type?: string
  imageURL?: any
  menuItems?: any
  mainmenuId?: any
  isLoginSignUpDisabled: boolean
}

const Header = (headerData: GlobalData) => {
  const { data: session } = useSession();
  let navigationList: any[] = [];
  if (!session) {
    navigationList.push({
      id: 'login',
      value: 'Login',
      hasSubMenus: false,
      clickableType: 'InSession',
      url: '/login',
      subMenuList: null,
      metaData: null,
    })
  }

  if (session) {
    navigationList.push(
      {
        id: 'credentials',
        value: 'Credentials',
        hasSubMenus: false,
        clickableType: 'InSession',
        url: '/credentials',
        subMenuList: null,
        metaData: null,
      },
      {
        id: 'profile',
        value: 'Profile',
        hasSubMenus: false,
        clickableType: 'InSession',
        url: '/profile',
        subMenuList: null,
        metaData: null,
      },
      {
        id: 'support',
        value: 'Support Tickets',
        hasSubMenus: false,
        clickableType: 'InSession',
        url: '/support',
        subMenuList: null,
        metaData: null,
      },
      {
        id: 'CustomerName',
        showDropDown: true,
        value: null,
        hasSubMenus: false,
        clickableType: 'InSession',
        url: '',
        subMenuList: null,
        metaData: {
          firstName: session.decodedAccessToken.firstName,
          lastName: session.decodedAccessToken.lastName,
          greeting: 'Hi',
        },
      }
    )
  }
  return (
    <>
      <PageHeader {...headerData}></PageHeader>
    </>
  )
}

export default Header
