import React from 'react'
import Elavon404 from './elavon/Elavon404'
import USBank404 from './usbank/USBank404'

export default function Custom404({ className }: { className?: string }) {
  if (process.env.SITE_BRAND === 'elavon') {
    return <Elavon404 />
  }
  return <USBank404 className={className} />
}
