import styles from '@/components/SkipLinks/skipLinks.module.scss'
import USBLink from '@usb-shield/react-link'
import { useRouter } from 'next/router'

export const SkipLinks = () => {

  const router = useRouter()
    
  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    let content = null
    if (router.pathname.includes("/products/")) {
      content = document.getElementById("markdown-content")
    } else {
      content = document.getElementById("content")
    }

    content?.focus()
    event.preventDefault()
    return false   
  }

  return (
    <div className={styles.skipLinks}>
        <USBLink
          linkType="basic"
          href="#"
          handleClick={clickHandler}
        >
          Skip to main content
        </USBLink>
      </div>
  )
}
