import footerStyles from '@/components/PageFooter/pageFooter.module.scss'
import Link from 'next/link'
import { USBIconExternalLink } from '@usb-shield/react-icons'
import { useGetUserInfoClientSide } from '@/utils/user/user.util'

const FooterLink = ({ index, link, text, openInNewTab }: {
  index: number,
  link: string,
  text: string,
  openInNewTab: boolean
}) => {
  const { userInfo } = useGetUserInfoClientSide()
  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  
  if (isElavon && text.toLowerCase() === 'beta participants' && userInfo !== null) {
    link = '/dashboard'
  }

  return (
    <>
      <li className={footerStyles.quicklinksListItem} key={index}>
        <Link href={link}>
          <a className={
            !isElavon
              ? footerStyles.quickLinkWithPipe
              : footerStyles.quickLinkWithPipeMobile
          }
             target={openInNewTab ? '_blank' : ''}
          >
            {text}
            {openInNewTab ? (<USBIconExternalLink id='external-link' />) : ('')}
          </a>
        </Link>
      </li>
    </>
  )
}

export default FooterLink