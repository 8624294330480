import React from 'react'
import PageFooter from '@/components/PageFooter/PageFooter'
import FooterQuickLinks from '@/components/FooterQuickLinks/FooterQuickLinks'
interface GlobalData {
  footerData?: any
}
const Footer = (footerDataDetails: GlobalData) => {
  return (
    <>
      {footerDataDetails?.footerData?.footerDetails?.footerQuickLinks?.length && (
        <FooterQuickLinks
          quickLinkData={footerDataDetails?.footerData?.footerDetails?.footerQuickLinks}
        ></FooterQuickLinks>
      )}
      <PageFooter
        footerData={footerDataDetails?.footerData}
        quickLinkData={footerDataDetails?.footerData?.footerDetails?.footerQuickLinks}
      ></PageFooter>
    </>
  )
}

export default Footer
